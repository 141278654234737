export default [
  /* */
  { name: '美學之作', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '豐富機能', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '快捷交通', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '時尚生活', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
  { name: '名品建材', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '',offset:0, mobileOffset: 0 , type: '' },
  { name: '精彩業績', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '',offset:0, mobileOffset: 0, type: '' },
 
  { name: '立即來電', imgSrc: '', subTitle: '', section: 'contact-info', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '',offset:0, mobileOffset: 0, type: 'btn' },
]
import { isMobile } from '../utils/index'
export default {
  address: '桃園市大園區環區西路與科二街交叉口',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7228.636743219547!2d121.1644956!3d25.0571962!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x428384c93ae59188!2zMjXCsDAzJzI1LjkiTiAxMjHCsDEwJzA3LjkiRQ!5e0!3m2!1szh-TW!2stw!4v1641534061186!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/LSSHwq82aCnRQKYV6',
  phone: '03-3855000',
  fbLink: 'https://www.facebook.com/nina.yufong',
  fbMessage: 'https://m.me/nina.yufong',
  caseName: '悅峰釀',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '悅峰建設股份有限公司',],
    ['建築規劃', '黃瀞萩建築師事務所',],
    ['戶數規劃', '24戶',],
    ['樓層規劃', '4層、5層',],
    ['建案位置', '桃園市大園區客運路二段253、253-1地號',],
  ],

  gtmCode: ['TKDXHGN'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
